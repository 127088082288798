<template>
  <el-container class="full-height bg-gray layout-child">
    <el-main class="page-content">
      <slot/>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'AppContent',
}
</script>

<style scoped>
.layout-child{
    flex: 1;
    overflow: auto;
  }
</style>
