const UserList = () => import('@enterpaise/vuepage/user/user-list')
const DepartmentList = () => import('@enterpaise/vuepage/user/departmental-list')
const AddOrUpdate = () => import('@enterpaise/vuepage/user/addorupdate')
const SeeDetails = () => import('@enterpaise/vuepage/user/seedetails')
const stanging = () => import('@enterpaise/vuepage/user/stanging-list')

export default [
  {
    path: '/user/user-list',
    component: UserList,
    meta: [
      {
        url: '',
        name: '用户管理',
      },
      {
        url: '/user/user-list',
        name: '员工列表',
      },
    ],
  },
  {
    path: '/user/departmental-list',
    component: DepartmentList,
    meta: [
      {
        url: '',
        name: '用户管理',
      },
      {
        url: '/user/user-list',
        name: '员工列表',
      },
      {
        url: '/user/departmental-list',
        name: '部门管理',
      },
    ],
  },
  {
    path: '/user/addorupdate/:id',
    component: AddOrUpdate,
    meta: [
      {
        url: '',
        name: '用户管理',
      },
      {
        url: '/user/user-list',
        name: '员工列表',
      },
      {
        url: '/user/addorupdate/:id',
        name: '添加',
      },
    ],
  },
  {
    path: '/user/seedetails/:id',
    component: SeeDetails,
    meta: [
      {
        url: '',
        name: '用户管理',
      },
      {
        url: '/user/user-list',
        name: '员工列表',
      },
      {
        url: '/user/seedetails/:id',
        name: '员工详情',
      },
    ],
  },
  {
    path: '/user/stanging-list',
    component: stanging,
    meta: [
      {
        url: '',
        name: '用户管理',
      },
      {
        url: '/user/user-list',
        name: '员工列表',
      },
      {
        url: '',
        name: '角色列表',
      },
    ],
  },
]
