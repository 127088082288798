<template>
    <router-view v-if="isRouterAlive"></router-view>
</template>

<script>
export default {
    name: 'App',
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isRouterAlive: true,
        };
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true;
            });
        },
    },
};
</script>

<style lang="scss">
@import 'assets/styles/reset.scss';
@import 'assets/styles/app.scss';
</style>
