<template>
  <section class="main-container">
    <section class="container-right">
      <slot name="right"/>
    </section>
    <slot name="left"/>
  </section>
</template>

<script>
export default {
  name: 'Toolbar',
}
</script>

<style lang="scss" scoped>
.main-container{
  min-height: 60px;
  /*position: relative;*/
  .container-right{
    position: relative;
    float: right;
    z-index: 100;
    /*top: 0;*/
    /*right:0;*/
  }
}

</style>
