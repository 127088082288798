const BuildOrder = () => import('@enterpaise/vuepage/floor/work/work-order')
const BuildInfo = () => import('@enterpaise/vuepage/floor/work/work-info')
const GoodsTravel = () => import('@enterpaise/vuepage/floor/goodstravel')
const GoodsDetails = () => import('@enterpaise/vuepage/floor/goods-details')

export default [
  {
    path: '/floor/work/work-order',
    component: BuildOrder,
    meta: [
      { name: '工单管理', url: '' },
      { name: '楼层工单', url: '/floor/work/work-order' },
    ],
  },
  {
    path: '/floor/work/work-info',
    component: BuildInfo,
    meta: [
      { name: '工单管理', url: '' },
      { name: '楼层工单', url: '/floor/work/work-order' },
      { name: '详情', url: '' },
    ],
  },
  {
    path: '/floor/goodstravel',
    component: GoodsTravel,
    meta: [
      {
        url: '',
        name: '楼层管理',
      },
      {
        url: '/floor/goodstravel',
        name: '物品出行',
      },
    ],
  },
  {
    path: '/floor/goods-details',
    component: GoodsDetails,
    meta: [
      {
        url: '',
        name: '楼层管理',
      },
      {
        url: '/floor/goodstravel',
        name: '物品出行',
      },
      {
        url: '',
        name: '详情',
      },
    ],
  },
]
