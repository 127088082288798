import Vue from 'vue';
import ElementUI from 'element-ui';
import App from '@enterpaise/App';
import router from '@enterpaise/router/index';
import 'element-ui/lib/theme-chalk/index.css';
import TopBar from '@enterpaise/components/top-bar';
import AppContent from '@enterpaise/components/app-content';
import DkTable from '@enterpaise/components/table-pagination';
import Toolbar from '@enterpaise/components/toolbar';
import mapselect from '@enterpaise/components/map-select';
import UploadImage from '@enterpaise/components/upload-image';
import UploadExcel from '@enterpaise/components/bulk-upload';

// vuex 配置
import store from '@enterpaise/vuex/store';

// quill 富文本编辑器
/* eslint-disable */
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import preview from 'vue-photo-preview';
import 'vue-photo-preview/dist/skin.css';

import '@enterpaise/filters/vue-filiter';
import '@enterpaise/jslib/verify-code.min';

// const options = {
//   fullscreenEl: false,
// };

Vue.use(preview);
Vue.config.productionTip = false;

Vue.use(ElementUI);

// 每个页面都需要用的组件可以注册成全局组件，方便每个页面使用
Vue.component('top-bar', TopBar);
Vue.component('app-content', AppContent);
Vue.component('dk-table', DkTable);
Vue.component('toolbar', Toolbar);
Vue.component('mapselect', mapselect);
Vue.component('upload-image', UploadImage);
Vue.component('upload-excel', UploadExcel);

Vue.prototype.$confirmDialog = message =>
    Vue.prototype.$confirm(message || '确定执行该操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
    });

// 打开新页面处理
window.pageOpen = function (path) {
    if (import.meta.env.VITE_NODE_ENV === 'development') {
        window.open('/pages/enterpaise' + path);
    } else {
        window.open(path);
    }
};

/* eslint-disable no-new */
window.vm = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});
/* eslint-disable no-new */
