const GoodsTravel = () => import('@enterpaise/vuepage/aduits/goodstravel');
const GoodsDetails = () => import('@enterpaise/vuepage/aduits/goods-details');
const MonthlyCard = () => import('@enterpaise/vuepage/aduits/monthlycard');
const MonthlyCardDetails = () =>
    import('@enterpaise/vuepage/aduits/monthlycard-details');
const MonthlyCardGroup = () =>
    import('@enterpaise/vuepage/aduits/monthlycard-group');
const MonthlyCardGroupDetails = () =>
    import('@enterpaise/vuepage/aduits/monthlycard-group-details');
const VisitorList = () => import('@enterpaise/vuepage/aduits/visitor-list');
const VisitorInfo = () => import('@enterpaise/vuepage/aduits/visitor-info');
const Blacklist = () => import('@enterpaise/vuepage/aduits/blacklist');

export default [
    {
        path: '/aduits/goods-details/:id',
        component: GoodsDetails,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '/aduits/goodstravel',
                name: '物品放行',
            },
            {
                url: '/aduits/goods-details/:id',
                name: '详情',
            },
        ],
    },
    {
        path: '/pro-examine-manage/card-examine',
        component: MonthlyCard,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '/pro-examine-manage/card-examine',
                name: '月卡审核',
            },
        ],
    },
    {
        path: '/aduits/goodstravel',
        component: GoodsTravel,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '/aduits/goodstravel',
                name: '物品放行',
            },
        ],
    },
    {
        path: '/aduits/monthlycard-details/:id',
        component: MonthlyCardDetails,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '/aduits/monthlycard',
                name: '月卡审核',
            },
            {
                url: '/aduits/monthlycard-details/:id',
                name: '详情',
            },
        ],
    },
    {
        path: '/aduits/monthlycard-group',
        component: MonthlyCardGroup,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '',
                name: '月卡组审核',
            },
        ],
    },
    {
        path: '/aduits/visitor-list',
        component: VisitorList,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '',
                name: '访客邀请',
            },
        ],
    },
    {
        path: '/aduits/blacklist',
        component: Blacklist,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '',
                name: '访客邀请',
            },
            {
                url: '',
                name: '访客黑名单',
            },
        ],
    },
    {
        path: '/aduits/visitor-list/info',
        component: VisitorInfo,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '/aduits/visitor-list',
                name: '访客邀请',
            },
            {
                url: '',
                name: '详情',
            },
        ],
    },
    {
        path: '/aduits/monthlycard-group-details/:id',
        component: MonthlyCardGroupDetails,
        meta: [
            {
                url: '',
                name: '审核管理',
            },
            {
                url: '/aduits/monthlycard-group',
                name: '月卡组审核',
            },
            {
                url: '',
                name: '详情',
            },
        ],
    },
];
