<template>
  <section class="container">
    <div id="container"/>
    <div id="myPageTop">
      <table>
        <tr>
          <td>
            <label>请输入关键字：</label>
          </td>
        </tr>
        <tr>
          <td>
            <input id="tipinput">
          </td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MapSelect',
  data() {
    return {
      map: '',
      marker: '',
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        lat: 39.90923,
        lng: 116.397428,
      }),
    },
  },
  mounted() {
    this.handler().initMap()
  },
  methods: {
    onLocation({ lnglat }) {
      if (this.marker) this.map.remove(this.marker)
      this.handler().addMaker(lnglat.lng, lnglat.lat)
      this.$emit('input', lnglat)
    },
    handler() {
      return {
        initMap: () => {
          // 初始化地图对象，加载地图
          let map = ''
          if (this.value.lat && this.value.lng) {
            map = new window.AMap.Map('container', {
              resizeEnable: true,
              zoom: 15,
              center: [this.value.lng, this.value.lat],
            });
          } else {
            map = new window.AMap.Map('container', {
              resizeEnable: true,
              zoom: 15,
            });
          }
          const autoOptions = {
            input: 'tipinput',
          };
          const auto = new window.AMap.Autocomplete(autoOptions);
          const placeSearch = new window.AMap.PlaceSearch({
            map,
          }); // 构造地点查询类
          window.AMap.event.addListener(auto, 'select', (e) => {
            placeSearch.setCity(e.poi.adcode);
            placeSearch.search(e.poi.name); // 关键字查询查询
          });// 注册监听，当选中某条记录时会触发
          map.on('click', this.onLocation)
          this.map = map
          if (this.value.lng && this.value.lat) {
            this.handler().addMaker(this.value.lng, this.value.lat)
          }
        },
        addMaker: (lng, lat) => {
          // 构造点标记
          const marker = new window.AMap.Marker({
            icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
            position: [lng, lat],
          });
          this.marker = marker
          this.map.add(marker)
          this.map.setFitView()
        },
        removeMaker() {
          // console.log(this.map, 'map')
          this.map.remove(this.marker)
          this.map.setFitView()
        },
      }
    },
  },
}
</script>

<style scoped>
  .container{
    width: 400px;
    height: 400px;

  }
 #container{
  left: unset;
  width: 400px;
  height: 400px;
}
</style>
