import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

const state = {
  userInfo: {},
}
export default {
  actions,
  getters,
  state,
  mutations,
}
