import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import base from './modules/base';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    base,
  },
  plugins: [createPersistedState()],
  strict: process.env.NODE_ENV !== 'production',
});
