<template>
    <div>
        <el-button type="primary" size="small" @click="openUpload"
            >上传文件</el-button
        >
        <input
            ref="file"
            type="file"
            style="display: none"
            multiple
            @change="onFileSelect"
        />
    </div>
</template>

<script>
import BulkUpload from '@enterpaise/mixins/bulk-upload';

export default {
    name: 'BulkUpload',
    mixins: [BulkUpload],
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        // 支持的文件格式
        fileTypes: {
            type: Array,
            default: () => ['xls', 'xlsx', 'doc', 'docx', 'pdf'],
        },
    },
    methods: {
        openUpload() {
            this.$refs.file.click();
        },
        onFileSelect() {
            const { fileTypes } = this;
            const files = [...this.$refs.file.files];

            for (let i = 0; i < files.length; i += 1) {
                const tmpFileName = files[i].name;
                const dotIndex = tmpFileName.lastIndexOf('.');

                if (dotIndex === -1) {
                    this.$message({
                        message: '不支持文件【' + tmpFileName + '】的格式！',
                        type: 'error',
                    });

                    return; // 没有点号，文件后缀无效
                }

                // 获取文件名后缀并转成小写
                const tmpExtension = tmpFileName
                    .toLowerCase()
                    .slice(dotIndex + 1);
                
                // console.log(tmpFileName, tmpExtension);

                if (!fileTypes.includes(tmpExtension)) {
                    this.$message({
                        message:
                            '仅支持 ' + fileTypes.join('、') + ' 格式的文件',
                        type: 'error',
                    });

                    return;
                } else {
                    // 设置文件名后缀
                    files[i].typeUrl = tmpExtension;
                }
            }

            // console.log(files);
            let fileList = [];

            files.forEach(i => {
                fileList.push({
                    name: i.name,
                    file: i,
                    percent: 0,
                    key: '',
                    size: i.size,
                });
            });

            fileList = [...this.value, ...fileList];

            const newList = [];
            const obj = {};

            fileList.forEach(i => {
                if (!obj[i.name]) {
                    obj[i.name] = i.name;
                    newList.push(i);
                }
            });

            // this.$emit('input', newList);

            // console.log(newList);

            this.$refs.file.value = '';

            this.initFileList(newList);
        },
    },
};
</script>

<style lang="scss" scoped></style>
