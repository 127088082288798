const AdminList = () => import('@enterpaise/vuepage/permission/admin-list');
const GroupList = () => import('@enterpaise/vuepage/permission/group-list');
const MenuList = () => import('@enterpaise/vuepage/permission/menu-list');
const OperationLogs = () =>
    import('@enterpaise/vuepage/permission/operation-logs');
export default [
    {
        path: '/permission/admin-list',
        component: AdminList,
        meta: [
            {
                url: '',
                name: '权限管理',
            },
            {
                url: '/permission/admin-list',
                name: '管理员管理',
            },
        ],
    },
    {
        path: '/permission/group-list',
        component: GroupList,
        meta: [
            {
                url: '',
                name: '权限管理',
            },
            {
                url: '/permission/group-list',
                name: '角色组管理',
            },
        ],
    },
    {
        path: '/permission/menu-list',
        component: MenuList,
        meta: [
            {
                url: '',
                name: '权限管理',
            },
            {
                url: '/permission/menu-list',
                name: '菜单管理',
            },
        ],
    },
    {
        path: '/permission/operation-logs',
        component: OperationLogs,
        meta: [
            {
                url: '',
                name: '权限管理',
            },
            {
                url: '/permission/operation-logs',
                name: '操作日志',
            },
        ],
    },
];
