const SupplierList = () => import('@enterpaise/vuepage/supplier/supplier-list')
const AddSupplier = () => import('@enterpaise/vuepage/supplier/add-supplier')

export default [
  {
    path: '/supplier/supplier-list',
    component: SupplierList,
    meta: [
      {
        url: '',
        name: '供应商管理',
      },
      {
        url: '/supplier/supplier-list',
        name: '供应商列表',
      },
    ],
  },
  {
    path: '/supplier/supplier-list/add',
    component: AddSupplier,
    meta: [
      {
        url: '',
        name: '供应商管理',
      },
      {
        url: '/supplier/supplier-list',
        name: '供应商列表',
      },
      {
        url: '/supplier/supplier-list/add',
        name: '新增/编辑',
      },
    ],
  },
]
