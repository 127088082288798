import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import Example from '@enterpaise/router/example';
import Commodity from '@enterpaise/router/commodity';
import Permission from '@enterpaise/router/permission';
import User from '@enterpaise/router/user';
import Aduits from '@enterpaise/router/aduits';
import Work from '@enterpaise/router/work';
import Supplier from '@enterpaise/router/supplier';
// progress bar style
NProgress.configure({ showSpinner: false });

const Login = () => import('@enterpaise/vuepage/login');
const Index = () => import('@enterpaise/vuepage/Index');
const Apply = () => import('@enterpaise/vuepage/apply');
const children = [
    {
        path: '',
        redirect: '/example-management/multiple-download',
    },
].concat(
    ...Example,
    ...User,
    ...Aduits,
    ...Commodity,
    ...Permission,
    ...Work,
    ...Supplier
);

// console.log(Example);

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '',
            redirect: '/login',
        },
        {
            path: '/login',
            component: Login,
        },
        {
            path: '/apply',
            component: Apply,
        },
        {
            path: '/index',
            component: Index,
            children,
        },
    ],
});

router.beforeEach((to, from, next) => {
    // eslint-disable-next-line no-param-reassign
    NProgress.start();
    next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    NProgress.done();
});

export default router;
